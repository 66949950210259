@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#title {
  position: relative;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: 'Sahitya', serif;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 0;
  line-height: 20px;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.19);
}
#title span {
  background: -webkit-linear-gradient(#ffffff75, #ffffff75);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


#title-small {
  position: relative;
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: 'Sahitya', serif;
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 15px;
  text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.19);
}

/* line 86, ../sass/hg.sass */
#title-small span {
  background: -webkit-linear-gradient(#ffffff75, #ffffff75);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

